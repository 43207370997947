import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { routes } from "./routes";
import { Toaster } from "react-hot-toast";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

function App() {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      supportedLngs: ["en", "bn"],
      fallbackLng: "en",
      detection: {
        order: ["cookie", "path", "htmlTag"],
        caches: ["cookie"],
      },
      backend: {
        loadPath: "/assets/locales/{{lng}}/translation.json",
      },
      react: { useSuspense: false },
    });

  return (
    <>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => {
            const Layout = route.layout;
            return (
              <Route
                element={
                  <Layout>
                    <Outlet />
                  </Layout>
                }
                key={index}
              >
                <Route path={route.path} element={route.component} />
              </Route>
            );
          })}
        </Routes>
        <Toaster position="top-center" reverseOrder={false} gutter={8} />
      </BrowserRouter>
    </>
  );
}

export default App;
