import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import * as htmlToImage from "html-to-image";
import toast from "react-hot-toast";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import DownloadIcon from "../common/icon/DownloadIcon";

const CardPreview = () => {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isIOs, setIsIOs] = useState(false);
  const [thumb, setThumb] = useState();
  const domEl = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    let platform = navigator?.userAgent || navigator?.platform || "unknown";
    if (/iPhone|iPod|iPad/.test(platform)) {
      setIsIOs(true);
    } else {
      setIsIOs(false);
    }
  }, []);
  useEffect(() => {
    if (!state) {
      navigate("/");
    }
  }, [state]);

  const downloadImageForAndroid = async () => {
    setLoading(true);
    try {
      const dataUrl = await htmlToImage.toPng(domEl.current, {
        skipAutoScale: true,
      });

      const link = document.createElement("a");
      link.download = "Greetings-card.png";
      link.href = dataUrl;
      link.click();
      setLoading(false);
      window.gtag("event", "DOWNLOAD_CARD");
    } catch (err) {
      toast.error("Something went wrong.Please Try again.");
      setLoading(false);
    }
  };

  const downloadImageForiOs = () => {
    let data = document.getElementById("domEl");
    setLoading(true);
    html2canvas(data, {
      allowTaint: true,
      removeContainer: true,
      backgroundColor: null,
      imageTimeout: 15000,
      logging: true,
      scale: 2,
      useCORS: true,
    })
      .then((canvas) => {
        const contentDataURL = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.download = "Greetings-card.png";
        link.href = contentDataURL;
        link.click();
        window.gtag("event", "DOWNLOAD_CARD");
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong.Please Try again.");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchOccasions();
  }, []);

  const fetchOccasions = async () => {
   setLoader(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_CARD_API}${state.occasion}`);
      if (response.ok) {
        const data = await response.json();
        if (data.status === "SUCCESS") {
          setLoader(false);
          setThumb(data.data.image);
        } else {
          console.error("API response status is not success:", data.error);
        }
      } else {
        console.error("Failed to fetch occasions:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching occasions:", error);
    }
  };


  return (
    <>
      <div className="relative px-3">
        <img
          className="hidden sm:block absolute top-0 left-0 w-full h-full object-cover object-top -z-10"
          src="/background.jpg"
          alt=""
        />
        <img
          className="sm:hidden absolute top-0 left-0 w-full h-full object-cover -z-10"
          src="/background-sm.jpg"
          alt=""
        />
        <img
          className="brand_logo absolute top-6 w-[70px] md:w-[100px] xl:w-[143px] 2xl:top-4  left-1/2 -translate-x-1/2 md:left-[90%]"
          src="/logo_robi_horizontal.svg"
          alt=""
        />
        <Link
          to="/"
          type="button"
          className="btn_start flex items-center justify-center group bg-[#5B2D90] hover:bg-purple-900 mt-8 md:ml-5 xl:ml-28 w-12 h-12 2xl:w-16 2xl:h-16 rounded-full transition-all"
        >
          <span><svg
              className="relative transition-colors duration-300 delay-200 group-hover:text-white ease"
              width="30"
              height="30"
              fill="#ffffff"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg></span>
        </Link>
        <div className="flex justify-center items-center h-screen flex-col">
          {loader ? 
            <div className="relative w-full h-[100px]">
              <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
              </svg>
            </div>
            :
            <>
            <div id="domEl" ref={domEl} className="relative box-shadow">
              <img src={`data:image/png;base64, ${thumb}`} alt="card" />

              <Draggable axis="both" bounds="parent" scale={1}>
                <div
                  style={{ color: "#231F20", cursor: "pointer" }}
                  className="absolute text-center bottom-[10%] xs:right-[5%] right-2"
                >
                  <h2 className="text-[8px] !text-[#f9fcfc] sm:text-[12px] xl:text-[16px] font-bold leading-[1.12] mb-[2px]">
                    {state?.name}
                  </h2>
                  <p className="text-[7px] !text-[#f9fcfc] sm:text-[10px] xl:text-[14px] leading-[1] mb-[2px]">
                    {state?.designation}
                  </p>
                  <p className="text-[7px] !text-[#f9fcfc] sm:text-[10px] xl:text-[14px] leading-[1]">
                    {state?.company}
                  </p>
                </div>
              </Draggable>
            </div>
            <button
            onClick={isIOs ? downloadImageForiOs : downloadImageForAndroid}
            type="button"
            className="mt-4 2xl:mt-6 bg-[#5B2D90] flex items-center gap-2 text-white hover:text-white hover:bg-purple-900 transition-all duration-300 ease-in  font-bold rounded-lg text-sm px-11 py-3 text-center"
            disabled={loading}
          >
            {loading ? t("downloading") : t("download")}
            {!loading && <DownloadIcon/>}
          </button>
            </>
          }
          
          
        </div>
      </div>
    </>
  );
};

export default CardPreview;
