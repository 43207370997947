import CardPreview from "./pages/CardPreview";
import DefaultLayout from "./common/components/DefaultLayout";
import Landing from "./pages/Landing";
import ErrorPage from "./pages/Error";

export const routes = [
  {
    path: "/",
    component: <Landing />,
    layout: DefaultLayout,
  },
  {
    path: "/card-preview",
    component: <CardPreview />,
    layout: DefaultLayout,
  },
  {
    path: "*",
    component: <ErrorPage />,
    layout: DefaultLayout,
  },
];
